.notificationBar {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 3px 2px 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--errorColor);
  font: var(--graphikMedium);
  color: #fff;
  span {
    line-height: 1.35;
  }
  a {
    color: inherit;
  }
}

.dismissErrorButton {
  margin-left: auto;
  margin-right: 0;
  border: none;
  width: 42px;
  height: 42px;
  background: url(../media/icon-exit-white.svg) no-repeat center center;
  background-size: 16px auto;
  cursor: pointer;
}
