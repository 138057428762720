@import 'media-queries';
@import 'font-mixins';

:root {
  --backColor: #f9f5f2;
  --green: #119784;
  --textColor: #172327;
  --ctaBackColor: #fbb416;
  --disabledButtonBackColor: #dddddd;
  --errorColor: #f04e4c;

  --graphikRegular: 400 14px Graphik-Regular, sans-serif;
  --graphikMedium: 500 14px Graphik-Medium, sans-serif;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  scroll-behavior: auto;
  [id='root'] {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  [class*='pin-to-bottom'] {
    margin: auto 0 0 0;
  }
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 15px;
  z-index: 10;
  gap: 10px;
  display: flex;
  background: #f9f5f2;
  text-align: right;
  border-bottom: 1px solid #dadada;
}

.columns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 80px 0;
  @include phone {
    flex-direction: column;
    padding: 0;
  }
}

.checkoutFormWrapper {
  padding: 15px;
  flex: 1;
  @include desktop {
    max-width: 400px;
  }
}

.checkoutForm {
  width: 100%;
  margin: auto;
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  position: relative;
}

.editPersonalDetailsSection {
  &.isMobile {
    display: none;
  }
  &.isShowing {
    display: block;
  }
}

.shippingSection {
  margin-top: 30px;
  display: none;
  &.isMobile {
    display: none;
  }
  &.isShowing {
    display: block;
  }
}

.legalSection {
  &.isMobile {
    display: none;
  }
  &.isShowing {
    display: block;
  }
  .stripeLogos {
    display: none;
    @include phone {
      display: block;
      border: none;
      padding: 0;
      margin: 0 0 24px 0;
    }
  }
}

.isShowing {
  display: block;
}

.sectionTitle {
  padding-top: 0;
  margin-top: 0;
  @include graphik-medium;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.chosenPlanWrapper {
  padding: 15px;
  @include desktop {
    max-width: 330px;
  }
}

.stripeLogos {
  margin: 36px 0;
  padding: 18px 0;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
  @include phone {
    display: none;
  }
  img {
    max-width: 100%;
    max-height: 100px;
  }
}

.planCard {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  @include phone {
    padding: 15px;
  }
}

.planName {
  color: var(--green);
  font-size: 17px;
  line-height: 1.5;
  @include graphik-medium;
  font-weight: 600;
  margin: 0 0 10px 0;
  padding: 0;
  @include phone {
    margin-bottom: 0;
    font-size: 14px;
  }
}
.price {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.5px;
  @include graphik-medium;
  font-weight: 600;
  margin: 0 0 4px 0;
  @include phone {
    margin-bottom: 0;
  }
}
.planDuration {
  font-size: 14px;
  line-height: 1.45;
  @include phone {
    font-size: 12px;
  }
}

.planFeatures {
  font-size: 15px;
  line-height: 1.5;
  list-style: disc;
  margin: 10px 0;
  padding-left: 1em;
  li {
    list-style: disc;
  }
  @include phone {
    font-size: 13px;
  }
}

.planSmallPrint {
  font-size: 12px;
  line-height: 20px;
}

.ctaButton {
  @include graphik-medium;
  font-weight: 600;
  font-size: 15px;
  text-shadow: none;
  color: var(--textColor);
  background: var(--ctaBackColor);
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px 12px;
  margin: 0 !important;
  border: none;
  cursor: pointer;
  min-height: 40px;
  position: relative;
  text-decoration: none;
  &[disabled] {
    background: var(--disabledButtonBackColor);
    color: #aaa;
  }
  svg {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.submitButton {
  width: 100%;
  padding: 15px;
  margin: 0 !important;
}

.legal {
  padding-top: 0;
  margin-top: 0;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  a {
    color: var(--green);
    text-decoration: underline;
  }
}

// checkbox
.legalWrapper {
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.checkbox {
  transform: translateY(2px);
  input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
  label {
    width: 36px;
    padding: 1px;
    border-radius: 35px;
    display: flex;
    cursor: pointer;
    background: #d9d9d9;
    transition: all 0.12s ease-out;
    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      border-radius: 18px;
      background: #fff;
      margin-left: 0;
      transition: all 0.12s ease-out;
    }
  }
}

.isChecked {
  label {
    background: #fff;
    &:before {
      background: var(--green);
      margin-left: calc(100% - 18px);
    }
  }
}

/// edit details
.personalDetails {
  background: var(--green);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  @include phone {
    padding: 10px;
  }
  span {
    font-size: 14px;
    color: #fff;
    + span {
      margin-top: 8px;
    }
  }
  strong {
    @include graphik-medium;
  }
  a,
  button {
    background: none;
    text-decoration: underline;
    color: #fff;
    border: none;
    padding: 8px 0;
  }
}

//testimonials
.testimonials {
  @include phone {
    display: none;
  }
  h3 {
  }
  blockquote {
    padding: 0;
    margin: 0;
    + blockquote {
      margin-top: 20px;
    }
  }
}

.citeAndName {
  font-size: 15px;
  line-height: 1.5;
  p {
    padding: 0;
    margin: 0 0 0.5rem 0;
  }
  cite {
    @include graphik-medium;
    font-style: normal;
  }
}

//activated page
.activatedPage {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1270px;
  width: 100%;
  margin: 125px auto auto auto;
  h1 {
    font-size: 38px;
    line-height: 1.4;
  }
  p {
    font-size: 17px;
    line-height: 1.45;
  }
  h1,
  p {
    padding-right: 15px;
    @include phone {
      padding: 0 15px;
    }
  }
  img {
    padding-left: 15px;
    max-width: 50%;
    @include phone {
      display: none;
    }
  }
}

.activatedPageCopy {
  width: 50%;
  padding-left: 90px;
  @include phone {
    width: 100%;
    padding: 0;
  }
}

.activatedPageFormFooter {
  margin-top: 60px;
  a {
    @include desktop {
      max-width: 50%;
    }
  }
}
