.suspensePreloader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  .spinner {
    position: static;
    transform: translate(0, 0);
  }
}
