* {
  box-sizing: border-box;
}

html {
  background: #f9f5f2;
}

body {
  margin: 0;
  padding: 0;
  color: $textColor;
  background: #f9f5f2;
  -webkit-font-smoothing: antialiased;
  @include graphik-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

br {
  font-family: Arial, sans-serif;
}

.server-message {
  position: fixed;
  transition: all 0.26s ease-in-out;
  z-index: 250;
  left: 0;
  top: 0;
  right: 0;
  padding: 15px;
  color: #fff;

  font-size: 16px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  opacity: 0;
  pointer-events: none;
  a {
    color: #fff;
  }
  &.type-warn {
    opacity: 1;
    background: $orange;
    pointer-events: auto;
  }
  &.type-success {
    opacity: 1;
    background: $green;
    pointer-events: auto;
  }
  &.type-error {
    opacity: 1;
    background: $errorRed;
    pointer-events: auto;
  }
  &.type-info {
    opacity: 1;
    background: $yellow;
    color: $textColor;
    pointer-events: auto;
    a {
      color: $textColor;
    }
  }
}

.suspense-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  .spinner {
    position: static;
    transform: translate(0, 0);
  }
}

.hide-mobile {
  @include phone {
    display: none !important;
  }
}
