@import 'media-queries';

.formFooter {
  text-align: center;
  @include phone {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    background: none;
  }
}

.isPinned {
  background: var(--backColor);
  &:before {
    @include phone {
      content: '';
      display: block;
      height: 15px;
      min-width: 100%;
      position: absolute;
      top: 0;
      left: -15px;
      right: -15px;
      z-index: 0;
      background: var(--backColor);
      transition: all 0.12s;
      box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.06);
    }
  }
}

.bottomPinRef {
  top: -1px;
}
