.email-instruction {
  font-size: 13px;
  line-height: 18px;
  display: flex;
  margin-bottom: 20px;
  .email-typo-suggestion + & {
    margin-top: 10px;
  }
  a {
    color: $green;
    &:hover {
      color: $greenDark;
    }
  }
  > div > span {
    display: block;
    //line-height: 1.4;
  }
  span + span {
    margin-top: 1em;
  }
}

.i-icon {
  transform: translateY(1px);
  width: 14px;
  height: 14px;
  display: flex;
  flex-shrink: 0;
  margin-right: 8px;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background: #767676;
  color: #fff;
  font-weight: bold;
  @include graphik-medium;
  font-size: 11px;
  line-height: 15px;
}
