input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $inputBorder;
  -webkit-text-fill-color: $textColor;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

legend {
  padding-left: 0;
  padding-right: 0;
}

.form-input {
  margin: 0 auto 20px auto;
  display: block;
  &.email-input {
    position: relative;
  }
  input {
    width: 100%;
    font-size: 16px;
    line-height: 1;
    color: $textColor;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 10px 12px;
    transition: all 0.12s ease-in-out;
    min-height: 40px;
    &:disabled {
      background: #efefef;
      cursor: not-allowed;
    }
  }
  select {
    width: 100%;
    font-size: 15px;
    line-height: 1;
    color: $textColor;
    appearance: none;
    border: 1px solid $inputBorder;
    border-radius: 4px;
    padding: 10px 12px;
    background: #fff url(../media/arrow-dropdown.svg) calc(100% - 12px) center
      no-repeat;
    background-size: auto 10px;
    min-height: 40px;
    &:focus,
    &:active {
      color: $textColor;
    }
  }
  label {
    @extend .label;
  }
  ::placeholder {
    color: rgba($textColor, 0.5);
  }
}

.required {
  background: url(../media/icon-required.svg) left top no-repeat;
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-left: 4px;
}

.is-valid-null,
.is-pristine,
.is-default-value {
  select,
  select:focus,
  select:active {
    color: rgba($textColor, 0.5);
  }
}

.input-container {
  position: relative;
}

.label-and-error {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4px;
  a {
    color: $green;
  }
}

.label {
  color: $textColor;
  font-size: 15px;
  line-height: 1;
  white-space: nowrap;
  display: block;
  @include graphik-medium;
}

.spinner {
  position: absolute;
  right: 5px;
  transform-origin: center;
  transform: scale(0.8);
  opacity: 1;
  transition: all 0.12s ease-in-out;
}

.is-valid-true {
  select {
    color: $textColor;
    border-left: 2px solid $green;
  }
  input[type='password'],
  input[type='number'],
  input[type='date'] {
    border-left: 2px solid $green;
  }
}

.is-touched {
  &.is-valid {
    select,
    input {
      border-left: 2px solid $green;
    }
    .input-message {
      color: $green;
    }
  }

  &.has-error {
    input,
    select {
      border-color: $errorRed;
    }
  }
}

.side-by-side-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  > * {
    flex-shrink: 0;
    margin: 0;
    width: 100%;
    max-width: calc(50% - 15px);
  }
  ~ * {
    margin-top: 30px;
  }
  &.mobile-side-by-side {
    > * {
      max-width: calc(50% - 15px);
      min-width: initial;
      margin-bottom: 0;
    }
  }
  &.mobile-stacked {
    > * {
      max-width: 100%;
      + * {
        margin-top: 25px;
      }
    }
  }
}

.stacked-password-comparison-with-info {
  .form-input:last-of-type {
    margin-bottom: 0;
  }
}

.input-error-message,
.input-message {
  display: block;
  color: $errorRed;
  font-size: 12px;
  line-height: 1;
  margin-left: auto;
  padding-left: 10px;
  white-space: nowrap;
  &.for-combined-password-field {
    max-width: 100%;
    text-align: left;
  }
}

.field-instructions {
  display: block;
  min-width: 100%;
  width: 100%;
  font-size: 14px;
  line-height: 1.35;
  color: #979797;
  margin-top: 6px;
  text-align: left;
}

.password-strength-container {
  .is-valid-false.is-blurred {
    input {
      border-color: $errorRed;
    }
  }

  .is-valid {
    input {
      color: $textColor;
      border-left: 2px solid $green;
    }
  }
}

.checkbox-container {
  position: relative;
  input[type='checkbox'] {
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    opacity: 0;
  }
  input[type='checkbox'] + label {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    padding-left: 30px;
    margin-bottom: 0;
    background: url(../media/icon-checkbox-unchecked.svg) left top no-repeat;
    background-size: auto 18px;
    min-height: 20px;
    position: relative;
    white-space: unset;
    &.preload {
      background-image: url(../media/icon-checkbox-checked.svg); //for preloading purposes
    }
    &:hover {
      cursor: pointer;
    }
  }
  input[type='checkbox']:checked + label {
    background-image: url(../media/icon-checkbox-checked.svg);
  }

  input[type='checkbox']:focus + label {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: $greenDark;
      opacity: 0.2;
      box-shadow: 0 0 8px 0 $greenDark;
      border-radius: 2px;
      pointer-events: none;
    }
  }
}

.account-phone-number {
  .has-checkbox {
    display: flex;
    align-items: center;
  }
}

.email-input {
  margin-bottom: 0;
}

.email-typo-suggestion {
  font-size: 12px;
  text-align: right;
  padding-top: 4px;
  min-height: 20px;
  @include phone {
    .page-2-columns & {
      margin-bottom: 12px;
    }
    .page-partner-email & {
      max-width: 100%;
    }
    .mini-form-container & {
      margin-bottom: 16px;
      transform: translateY(0);
    }
    .page-create-account-dtc & {
      max-width: none;
    }
  }
  a,
  button {
    border: none;
    background: none;
    line-height: 1;
    padding: 0;
    margin: 0;
    color: $green;
    text-decoration: underline;
    cursor: pointer;
  }
}
